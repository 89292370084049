.App {
  display: flex;
  gap: 0;
}

.left-side {
  background-color: #727cf5;
  /* height: 100vh; */
  width: 20%;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.right-side {
  background-color: #f0f3f8;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media print {
  .noPrint {
    display: none;
  }

  .left-side {
    display: none;
  }

  .p-header {
    font-size: 13px !important;
    margin-top: 10px !important;
  }

  .logo {
    display: block !important;
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .left-side {
    display: none;
  }

  .right-side {
    background-color: #f0f3f8;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
