.d-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.date-section {
  background-color: #727cf5;
  display: flex;
  align-items: center;
  width: 20%;
}

.date-section input {
  height: 100%;
  width: 80%;
}

.date-icon {
  color: #fff;
  margin: 10px;
}

.d-body {
  display: flex;
  flex-wrap: wrap;
}

.d-links {
  text-decoration: none;
}
.d-body section {
  width: 22%;
  background-color: #fff;
  height: 120px;
  padding: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.d-upper {
  display: flex;
  justify-content: space-between;
}

.d-titles {
  display: flex;
  flex-direction: column;
}

.d-titles h5 {
  color: #8a969c;
  margin: initial;
}

.d-titles h3 {
  color: #2e3262;
  margin: 30px 0;
  font-weight: bold;
}

.icon-cover {
  background-color: #dce0fd;
  height: 60px;
  padding: 10px 10px 0px 10px;
  border-radius: 3px;
}

.body-icons {
  color: #727cf5;
}

.d-green {
  color: green;
}

@media only screen and (max-width: 768px) {
  .left-side {
    display: none;
  }

  .d-header {
    display: none;
  }

  .d-body {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: #f0f3f8;
  }

  .d-body section {
    width: 90%;
    background-color: #fff;
    height: 200px;
    padding: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
}
