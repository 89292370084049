.cars-add-btn-dv {
  display: flex;
  justify-content: right;
  margin-right: 20px;
  margin-bottom: 20px;
}

.cars-list {
  margin: 0 20px;
}

.modal-content {
  width: 900px !important;
}
